/**
 * Inject Tailnwind's base styles
 */
@tailwind base;
@tailwind components;

/**
 * Add any custom component classes HERE before the utilities are loaded so
 * that the utilities can still override them.
 * According to the Tailwind docs.
 */

@tailwind utilities;

.content {
    border: 1 solid black;
}

img.preview {
    max-width: 200px;
}

img.full {
    max-width: 90vw;
    max-height: 90vh;
}

.height-wrapper {
    max-height: 90%;
    max-width: 90%;
}
